import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify' // path to vuetify export
import VueI18n from 'vue-i18n'
import ruLocale from './locales/ru.json'
import euLocale from './locales/eu.json'
import YmapPlugin from 'vue-yandex-maps'


Vue.use(YmapPlugin)
Vue.config.productionTip = false

const messages = {
    eng: euLocale,
    ru: ruLocale
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'eng',
    messages
})

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
