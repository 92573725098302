<template>
  <div class="section" id="how-to-find-section">
    <v-container class="find-section black pa-0 pt-2 pb-2" fluid>
      <v-container class="white pt-10 pb-10" fluid>
        <v-row class="justify-center">
          <v-col cols="6" sm="auto" class="find-title justify-center">
            <v-row class="fill-height align-center justify-center" v-html="$t('howToFind.title')" no-gutters>
              Как нас найти

            </v-row>
          </v-col>
          <v-col cols="6" sm="auto">
            <v-row class="justify-center">
              <v-col cols="auto" class="find-text">
                <div class="actual-address__address" v-html="$t('contacts.address')">423452, Республика Татарстан, г.
                  Альметьевск, ул. Ленина, дом 13
                </div>
                <div>public@geoindicator.com</div>
                <div v-html="$t('contacts.name')">+7 917 854 6850, Шакиров Артур Альбертович, генеральный директор</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <div class="map">
        <YMap @map-was-initialized="initMap" :settings="settings" :coords="coords"></YMap>
      </div>

    </v-container>
  </div>
</template>

<script>
import {loadYmap} from "vue-yandex-maps"
import YMap from "@/components/YMap";

export default {
  name: "HowToFind",
  props: ['locale'],
  data() {
    return {
      settings: {},
      coords: [54.90400391171737, 52.30866985411729]
    }
  },
  methods: {
    initMap(map) {
      this.map = map
      const myGeoObject = new window.ymaps.GeoObject({
        // Описание геометрии.
        geometry: {
          type: "Point",
          coordinates: [54.90400391171737, 52.30866985411729]
        },
      })
      this.map.geoObjects.add(myGeoObject)
    },
    async reloadMap() {
      this.map.destroy()
      window.ymaps = null
      document.getElementById("vue-yandex-maps").remove()
      this.settings.lang = 'ru_RU'

      if (this.locale !== 'RU') {
        this.settings.lang = 'en_US'
      }
      await loadYmap(this.settings)
    }
  },
  watch: {
    async locale() {
      await this.reloadMap()
    }
  },
  beforeMount() {
    let mapLang = 'ru_RU'

    if (this.locale !== 'RU') {
      mapLang = 'en_US'
    }
    this.settings = {
      apiKey: '',
      lang: mapLang,
      coordorder: 'latlong',
      enterprise: false,
      controls: ['fullscreenControl']
    }
  },
  components: {
    YMap
  }
}
</script>

<style scoped lang="scss">

.find-title {
  color: #009999;
  font-weight: bold;
  font-size: 2vw;
}

.actual-address {

  &__title {
    color: #009999;
    font-weight: bold;
    font-size: 1vw;
  }

  .find-section {
    background-color: black !important;
  }

}

.map {
  height: 40vh;
}

.map-image {
  height: 40vh;
  width: 100%;
  object-fit: cover;
}

.find-text {
  font-size: .9vw;
  font-weight: 500;
}

.ymap-container {
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {

  .find-title {
    font-size: 20px;
  }

  .find-text {
    font-size: 10px;
  }

}


</style>
