<template>
  <div class="section complex-section">
    <v-container class="fill-height complex-section-wrapper" fluid>
      <v-container class="justify-center align-center">
        <v-row class="section-title" v-html="$t('complex.title')">Комплексные исследования <br> пластового флюида</v-row>
        <v-row class="align-end justify-center">
          <v-col>
            <div class="text text-justify first-section-text" v-html="$t('complex.text1')">
              Компания <span class="bold">GEOINDICATOR</span> оказывает услуги в области комплексных исследований пластовых флюидов, обеспечивает геохимический монторинг для решения широкого спектра задач в нефтегазовой отрасли.
            </div>
            <v-row class="first-alert justify-end align-end" no-gutters>
              <div class="first-alert__name alert-name" v-html="$t('complex.title1')">
                СТАРТАП <br>
                «ГЕОХИМИЧЕСКИЕ
                ТЕХНОЛОГИИ»
              </div>
              <div class="first-alert__year alert-year">
                2019
              </div>
            </v-row>
          </v-col>
          <v-col class="align-self-end" cols="auto">
            <div class="image-wrapper img-right">
              <div class="z-img">
                <img src="../assets/img/kpfu.jpg" alt="">
              </div>
              <div class="svg_back1"></div>
              <div class="circle">
                <div class="circle__inner"></div>
                <div class="right-hider"></div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="second-row align-cent er justify-center" no-gutters>
          <v-col class="align-self-end second-image-col" cols="auto">
            <div class="img-back1">
              <div class="image-wrapper img-left second-image">
                <div class="z-img">
                  <img src="../assets/img/complex_man.jpg" alt="">
                </div>
                <div class="svg_back2"></div>
                <div class="circle">
                  <div class="circle__inner"></div>
                  <div class="left-hider"></div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="text text-justify second-section-text" v-html="$t('complex.text2')">
              За годы работы по данному направлению накоплена обширная база по основным гидрогеологическим
              комплексам,
              вмещающим залежи углеводородов, аналитическая работа осуществляется на основе имеющейся информации и
              актуализации данных по каждому конкретному месторождению.
              <br>
              Геохимические исследования пластовых систем это комплексная технология, находящаяся на стыке геологии,
              химии и разработки месторождений, особенностей работы скважин, и применения современных методов
              исследований там, где традиционные методы не возможны.
            </div>
            <v-row class="second-alert justify-start align-end" no-gutters>
              <div class="second-alert__year alert-year">
                2020
              </div>
              <div class="second-alert__name alert-name" v-html="$t('complex.title2')">
                НАРАБОТКА БАЗЫ ДАННЫХ <br> И ПИЛОТНЫХ ПРОЕКТОВ
              </div>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="align-center justify-start third-row" no-gutters>
          <v-col class="align-self-start">
            <div class="text text-justify third-section-text" v-html="$t('complex.text3')">
              Комплекс Geoindicator успешно зарекомендовал себя в решении пилотных проектов. С применением данной технологии Geoindicator разработано более 5 карбонатных и терригенных месторождений.
            </div>
            <v-row class="third-alert justify-end align-end" no-gutters>
              <div class="third-alert__name alert-name" v-html="$t('complex.title3')">
                ПРОМЫШЛЕННОЕ <br> ВНЕДРЕНИЕ ТЕХНОЛОГИИ
              </div>
              <div class="third-alert__year alert-year">
                2021
              </div>
            </v-row>
          </v-col>
          <v-col class="align-self-end third-image-col" cols="auto">
            <div class="image-wrapper third-image-wrapper img-right">
              <div class="z-img">
                <img src="../assets/img/complex3.png" alt="">
              </div>
              <div class="svg_back3"></div>
              <div class="circle">
                <div class="left-hider" style="background-color: #009797"></div>
                <div class="circle__inner"></div>
              </div>
            </div>
          </v-col>
        </v-row>

      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Complex"
}
</script>

<style scoped lang="scss">
.z-img {
  position: relative;
  z-index: 5;
  height: 10vw;
  width: 10vw;
}

.bold {
  font-weight: bold;
}

.section-title {
  font-weight: bold;
  font-size: min(3vw, 50px);
  line-height: min(3vw, 50px);
  color: #009999;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
}

.image-wrapper {
  border-radius: 50%;

  height: 10vw;
  width: 10vw;
  position: relative;
  display: block;
  margin-left: auto;

  img {
    height: 100%;
    border-radius: 50%;
  }
}

.third-image-wrapper {
  margin-right: auto;
  margin-left: 0;
}

.text {
  font-size: 1vw;
  font-weight: 500;
  line-height: 150%;

  &::first-letter {
    margin-left: 1rem;
  }
}

.circle {
  position: absolute;
  height: 2vw;
  width: 2vw;
  background-color: #009797;
  border-radius: 50%;
  bottom: -1vw;
  left: calc(50% - 1vw);
  z-index: 20;

  &__inner {
    position: absolute;
    height: 1.2vw;
    width: 1.2vw;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .right-hider {
    position: absolute;
    height: 0.3vw;
    width: 100vw;
    background-color: white;
    top: 50%;
    left: 100%;
    z-index: 10;
  }

  .left-hider {
    position: absolute;
    height: 0.3vw;
    width: 20vw;
    background-color: white;
    top: 50%;
    left: 100%;
    z-index: 10;
    margin-left: calc(-20vw - 2vw);
  }
}

.svg_back1 {
  position: absolute;
  width: 20vw;
  height: 17vw;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  background: url("../assets/img/complex1.svg") center center;
  background-size: cover;
}

.svg_back2 {
  position: absolute;
  width: 17vw;
  height: 17vw;
  bottom: 0;
  left: 0;
  transform: translate(-30%, 0);
  z-index: 2;
  background: url("../assets/img/complex2.svg") center center;
  background-size: cover;
}

.svg_back3 {
  position: absolute;
  width: 17vw;
  height: 17vw;
  bottom: 0;
  left: 0;
  transform: translate(-10%, 15%);
  z-index: 2;
  background: url("../assets/img/complex3.svg") center center;
  background-size: cover;
}

.complex-section {
  min-height: 100vh;
}

.complex-section-wrapper {
  padding-left: 20%;
  padding-right: 20%;
  min-height: 100vh;
}

.first-section-text {
  padding-right: 5rem;
}

.second-section-text {
  border-top: #009797 0.3vw solid;
  border-left: #009797 0.3vw solid;
  border-radius: 4vw 0 0 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 8vw;
}

.second-image-col {
  margin-right: -5.3vw;
}

.third-section-text {
  border-top: #009797 0.3vw solid;
  border-right: #009797 0.3vw solid;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 8vw;
  border-radius: 0 4vw 0 0;
}

.third-image-col {
  margin-left: -5.3vw;
}

.first-alert {
  font-size: 0.8vw;
  text-align: center;
  font-weight: 500;
  padding-top: 1rem;

  &__name {
    background-color: #009797;
    border-radius: 2rem 2rem 0 0;
    padding-right: 7vw;
    padding-left: 2rem;
    color: white;
  }

  &__year {
    color: #009999;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.6vw;
    border-radius: 2rem 2rem 0 0;
    margin-left: -4vw;
    background-color: white;
    height: 100% !important;
    font-weight: bold;
  }
}

.second-alert {
  font-size: 0.8vw;
  text-align: center;
  font-weight: 500;
  padding-top: 1rem;
  padding-left: 8vw;

  &__name {
    background-color: #009797;
    border-radius: 2rem 2rem 0 0;
    padding-left: 7vw;
    padding-right: 2rem;
    color: white;
  }

  &__year {
    color: #009999;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.6vw;
    border-radius: 2rem 2rem 0 0;
    margin-right: -4vw;
    background-color: white;
    height: 100% !important;
    font-weight: bold;
    z-index: 100;
  }
}

.third-alert {
  font-size: 0.8vw;
  text-align: center;
  font-weight: 500;
  padding-top: 1rem;
  padding-right: 8vw;

  &__name {
    background-color: #009797;
    border-radius: 2rem 2rem 0 0;
    padding-right: 7vw;
    padding-left: 2rem;
    border-bottom: 0.3vw #009797 solid;
    color: white;
    margin-bottom: -0.3vw;
  }

  &__year {
    color: #009999;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.6vw;
    border-radius: 2rem 2rem 0 0;
    margin-left: -4vw;
    background-color: white;
    font-weight: bold;
    padding-top: 0.6vw;
    margin-bottom: -0.3vw;
  }
}

@media (max-width: 600px) {
  .complex-section {
    min-height: 0;
  }

  .complex-section-wrapper {
    min-height: 0;
    overflow: hidden;
    padding-left: 5%;
    padding-right: 5%;
  }

  .first-section-text {
    padding-right: .5rem;
  }

  .second-section-text {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 4vw;
  }

  .third-section-text {
    padding-top: 1rem;
    padding-bottom: .5rem;
    padding-right: 4vw;
    border-radius: 0 4vw 0 0;
  }

  .first-alert {

    &__name {
      padding-right: 12vw;
    }

    &__year {
      margin-left: -9vw;
    }
  }

  .second-alert {

    &__name {
      padding-left: 12vw;
    }

    &__year {
      margin-right: -9vw;
    }
  }

  .third-alert {

    &__name {
      padding-right: 12vw;
    }

    &__year {
      margin-left: -9vw;
    }
  }

  .text {
    font-size: 2vw;
    font-weight: 500;
    line-height: 150%;
  }

  .section-title {
    line-height: 100%;
    font-size: 6vw;
  }

  .alert-year {
    font-size: 4vw;
  }

  .alert-name {
    font-size: 2vw;
  }

  .left-hider {
    height: 0.5vw !important;
    width: 30vw !important;
    margin-left: calc(-30vw - 2vw) !important;
  }
}

</style>
