<template>
  <div class="section cases-section">
    <v-container fluid class="fill-height pa-0">
      <v-carousel
        class="fill-height"
        height="100vh"
        id="carousel2"
        hide-delimiter-background
      >
        <v-carousel-item
          class="fill-height"
          eager
        >

          <v-container class="fill-height pa-0" fluid>
            <v-row class="justify-center fill-height align-center">
              <v-col md="8" class="pa-10 case-wrapper">
                <v-row>
                  <div class="case-number"><span v-html="$t('cases.title')">CASE</span> #1</div>
                </v-row>
                <v-row>
                  <div class="case-title" v-html="$t('cases.case1.name')">Cutting Zone</div>
                </v-row>
                <v-row class="first-case-row">
                  <v-col cols="12" sm="6">
                    <ul>
                      <li v-html="$t('cases.case1.text1')">Geomarker analysis showed that <span
                        style="font-weight: bold;color: #009797;">Formation A</span> (C1bb) and <span
                        style="font-weight: bold;color: #009797;">Formation B</span> (C1turne) brines are distinguished
                        by composition
                      </li>
                      <li v-html="$t('cases.case1.text2')">Producer well No. 49 has an intermediate value of markers B
                        and D
                      </li>
                      <li v-html="$t('cases.case1.text3')">Potential cause — crossflow between beds</li>
                      <li v-html="$t('cases.case1.text4')">Results have been proved by field development analysis —
                        <span style="font-weight: bold;color: #009797;">Formation B</span> OWC went up and flooded the
                        upper horizon
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <img src="../assets/cases/case1_1_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                    <img src="../assets/cases/case1_1_eng.png" alt="" v-else>
                  </v-col>
                </v-row>
                <v-row>
                  <img src="../assets/cases/case1_2_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                  <img src="../assets/cases/case1_2_eng.png" alt="" v-else>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>


        <v-carousel-item
          class="fill-height"
          eager
        >
          <v-container class="fill-height pa-0" fluid>
            <v-row class="justify-center fill-height align-center">
              <v-col sm="8" class="pa-10 case-wrapper">
                <v-row>
                  <div class="case-number"><span v-html="$t('cases.title')">CASE</span> #2</div>
                </v-row>
                <v-row class="mb-3">
                  <div class="case-title" v-html="$t('cases.case2.name')">Locating the source of water trouble
                  </div>
                </v-row>
                <v-row>
                  <v-col md="8">
                    <v-row>
                      <ul>
                        <li v-html="$t('cases.case2.text1')">Dual casing <span
                          class="colored">Well 20 (C1kz + D3zv)</span></li>
                        <li v-html="$t('cases.case2.text2')">Differences in trace component M have been indicated</li>
                        <li v-html="$t('cases.case2.text3')">Shutting of bottom horizon is recommended as the water has
                          been categorized as a D3zv
                          brine
                        </li>
                      </ul>
                    </v-row>
                    <v-row class="bottom-image-top">
                      <img src="../assets/cases/case2_1.png" alt="">
                    </v-row>
                  </v-col>
                  <v-col md="4">
                    <img src="../assets/cases/case_2_2_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                    <img src="../assets/cases/case2_2_eng.png" alt="" v-else>
                  </v-col>
                  <v-row class="bottom-image-bot">
                    <img src="../assets/cases/case2_1.png" alt="">
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>


        <v-carousel-item
          class="fill-height"
          eager
        >
          <v-container class="fill-height pa-0" fluid>
            <v-row class="justify-center fill-height align-center">
              <v-col md="8" class="pa-10 case-wrapper">
                <v-row>
                  <div class="case-number"><span v-html="$t('cases.title')">CASE</span> #3</div>
                </v-row>
                <v-row>
                  <div class="case-title" v-html="$t('cases.case3.name')">Water injection monitoring</div>
                </v-row>
                <v-row class="third-case-row-pc">
                  <v-col md="8">
                    <v-row class="mt-10">
                      <v-col md="10">
                        <ul>
                          <li v-html="$t('cases.case3.text1')">Mapping of brine composition areal distribution</li>
                          <li v-html="$t('cases.case3.text2')">Influence of injection in southern part and absence of
                            effect in northern part were
                            observed
                          </li>
                          <li v-html="$t('cases.case3.text3')">Contribution of injected wells has been
                            calculated for each well
                          </li>
                        </ul>
                      </v-col>
                      <v-col md="2">
                        <img src="../assets/cases/case3_1_ru.jpg" alt="" v-if="$i18n.locale === 'ru'">
                        <img src="../assets/cases/case3_1_eng.png" alt="" v-else>
                      </v-col>
                    </v-row>
                    <v-row>
                      <img src="../assets/cases/case3_2_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                      <img src="../assets/cases/case3_2_eng.png" alt="" v-else>
                    </v-row>
                  </v-col>
                  <v-col md="4">
                    <img src="../assets/cases/case3_3_eng.png" alt="">
                  </v-col>
                </v-row>
                <v-row class="third-case-row-phone">
                  <v-row>
                    <v-col cols="10">
                      <ul>
                        <li v-html="$t('cases.case3.text1')">Mapping of brine composition areal distribution</li>
                        <li v-html="$t('cases.case3.text2')">Influence of injection in southern part and absence of
                          effect in northern part were
                          observed
                        </li>
                        <li v-html="$t('cases.case3.text3')">Contribution of injected wells has been
                          calculated for each well
                        </li>
                      </ul>
                    </v-col>
                    <v-row class="justify-center">
                      <v-col cols="4" class="align-self-end">
                        <img src="../assets/cases/case3_1_ru.jpg" alt="" v-if="$i18n.locale === 'ru'">
                        <img src="../assets/cases/case3_1_eng.png" alt="" v-else>
                      </v-col>
                      <v-col cols="6" class="third-case-image">
                        <img src="../assets/cases/case3_3_eng.png" alt="">
                      </v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <v-col cols="10">
                        <img src="../assets/cases/case3_2_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                        <img src="../assets/cases/case3_2_eng.png" alt="" v-else>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>


        <v-carousel-item
          class="fill-height"
          eager
        >
          <v-container class="fill-height pa-0" fluid>
            <v-row class="justify-center fill-height align-center">
              <v-col md="8" class="pa-10 pb-0 case-wrapper">
                <v-row>
                  <v-col cols="12" sm="6" class="mb-5">
                    <v-row>
                      <div class="case-number"><span v-html="$t('cases.title')">CASE</span> #4</div>
                    </v-row>
                    <v-row>
                      <div class="case-title" v-html="$t('cases.case4.name')">Water Flooding Efficiency</div>
                    </v-row>
                    <v-row class="">
                      <ul>
                        <li v-html="$t('cases.case4.text1')">The impact of injection on the pressure in the drainage
                          area of the producing wells was
                          quantitatively assessed
                        </li>
                      </ul>
                    </v-row>
                    <v-row class="mt-10">
                      <img src="../assets/cases/case4_1_ru.png" style="width: 90%; display: block; margin: auto" alt=""
                           v-if="$i18n.locale === 'ru'">
                      <img src="../assets/cases/case4_1_eng.png" style="width: 90%; display: block; margin: auto" alt=""
                           v-else>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" class="pl-10 mb-10">
                    <v-row>
                      <img src="../assets/cases/case4_2_ru.png" style="display: block; margin: auto" alt=""
                           v-if="$i18n.locale === 'ru'">
                      <img src="../assets/cases/case4_2_eng.png" style=" display: block; margin: auto" alt="" v-else>
                    </v-row>
                    <v-row>
                      <div class="list-title" v-html="$t('cases.case4.text2')">
                        Recommendations
                      </div>
                    </v-row>
                    <v-row>
                      <ul>
                        <li v-html="$t('cases.case4.text3')">The reservoir pressure continues to decrease in the
                          drainage area of the producing wells. To reverse this trend and maintain reservoir pressure,
                          it is recommended to increase injection in wells No.12 and 13, and keep injection in well
                          No.11.
                        </li>
                        <li v-html="$t('cases.case4.text4')">The influence from injector wells No.12, 13, 11 has not
                          been revealed in wells No 7, 10, 5.
                        </li><li v-html="$t('cases.case4.text5')">The influence from injector wells No.12, 13, 11 has not
                          been revealed in wells No 7, 10, 5.
                        </li>
                      </ul>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>


        <v-carousel-item
          class="fill-height"
          eager
        >
          <v-container class="fill-height pa-0" fluid>
            <v-row class="justify-center fill-height align-center">
              <v-col md="8" class="pa-10 case-wrapper">
                <v-row>
                  <div class="case-number"><span v-html="$t('cases.title')">CASE</span> #5</div>
                </v-row>
                <v-row>
                  <div class="case-title" v-html="$t('cases.case5.name')">Fracture propagation in rocks</div>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-row class="mt-5 mb-2">
                      <ul>
                        <li v-html="$t('cases.case5.text1')">Horizontal well after fracking</li>
                        <li v-html="$t('cases.case5.text2')">Differences in brine composition have been identified</li>
                        <li v-html="$t('cases.case5.text3')">Contribution into oil production for fracked wells was
                          calculated.
                        </li>
                      </ul>
                    </v-row>
                    <v-row>
                      <img src="../assets/cases/case5_1_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                      <img src="../assets/cases/case5_1_eng.png" alt="" v-else>
                    </v-row>
                    <v-row>
                      <div class="case-text" v-html="$t('cases.case5.text4')">
                        composition revealed that more than 60% of production is coming from С2bash which is not a
                        fracking target horizon.
                      </div>
                    </v-row>
                  </v-col>
                  <v-col md="6" class="pl-10">
                    <v-row>
                      <img src="../assets/cases/case5_2_ru.png" alt="" v-if="$i18n.locale === 'ru'">
                      <img src="../assets/cases/case5_2_eng.png" alt="" v-else>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Cases"
}
</script>

<style lang="scss">

.cases-section {
  min-height: 100vh;
}

.case-number {
  color: #009797;
  font-weight: bold;
  font-size: 2vw;
}

.case-title {
  font-weight: bold;
  font-size: 2.2vw;
}

.cases-section img {
  width: 100%;
}

.cases-section ul {

  list-style-type: square;


  li {
    margin-bottom: 0;
    font-size: 1vw;

    &::marker {
      font-size: 2rem;
    }
  }
}

.case-text {
  margin-top: 1.5rem;
  font-size: 1vw;
}

.colored {
  font-weight: bold;
  color: #009797;
}

.list-title {
  margin-top: 2rem;
  font-size: 1.2vw;
  color: #009797;
  font-weight: bold;
}

.case-wrapper {
  border: 2px #009999 solid;
}

.bottom-image-bot {
  display: none !important;
}

.third-case-row-phone {
  display: none !important;
}

@media (max-width: 600px) {
  .cases-section {
    min-height: 0;
  }

  #carousel2 {
    height: auto !important;
  }

  #carousel2 .v-window__container {
    height: auto !important;
  }

  #carousel2 .v-carousel__item {
    height: auto !important;
    padding-bottom: 5rem;
  }

  .case-wrapper {
    border: none;
  }

  .cases-section ul {

    list-style-type: square;

    li {
      margin-bottom: 5px;
      font-size: 13px;

      &::marker {
        font-size: 1rem;
      }
    }
  }

  .case-number {
    font-size: 20px;
  }

  .case-title {
    font-weight: bold;
    font-size: 25px;
  }

  .case-text {
    margin-top: 1.5rem;
    font-size: 13px;
  }

  .bottom-image-bot {
    display: block !important;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .bottom-image-top {
    display: none !important;
  }

  .first-case-row {
    flex-direction: column-reverse !important;
  }

  .third-case-row-pc {
    display: none !important;
  }

  .third-case-row-phone {
    display: flex !important;
  }

  .list-title {
    font-size: 20px;
  }
}

</style>

<style lang="scss">
.v-btn .v-btn__content .v-icon {
  color: black !important;
}
</style>
