<template>
  <div class="section" id="partners-section">
    <div class="partners-title" v-html="$t('partners.title')">НАШИ ПАРТНЕРЫ</div>
    <v-container class="fill-height" fluid>
      <v-row class="mt-16">
        <v-row class="justify-center align-end">
          <v-col md="4" class="deposit-image-col">
            <img src="../assets/img/deposits_image.jpg" alt="">
          </v-col>
          <v-col md="4" class="partners-image-col justify-end align-end">
            <img src="../assets/img/partners.png" alt="">
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col md="8" class="text-center partners-text" v-html="$t('partners.text')">Мы готовы к сотрудничеству со всеми. <br> Нам доверяют. Мы это ценим!</v-col>
        </v-row>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Partners"
}
</script>

<style scoped lang="scss">
#partners-section {
  height: 100vh;
  min-height: 100vh;
  background-size: cover;
  position: relative;
}

.deposit-image-col {
  img {
    width: 100%;
  }
}

.partners-image-col {
  img {
    width: 80%;
  }
}

.partners-text {
  color: #009797;
  font-size: 2vw;
  font-weight: bold;
}

.partners-title {
  position: absolute;
  width: 70vw;
  background-color: rgb(0, 151, 151, 0.8);
  color: white;
  font-size: 2.5vw;
  padding: 1.5rem;
  left: 40%;
  top: 2%;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 3px;
  font-weight: 600;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
}

@media (max-width: 600px) {
  #partners-section {
    height: auto;
    min-height: 0;
    position: relative;
    //background-image: url("../assets/img/partners-mobile-background.png");
    //background-size: cover;
    overflow: hidden;
  }

  img {
    display: block;
    margin: auto;
  }

  .partners-title {
    position: relative;
    width: 100% !important;
    font-size: 25px;
    left: 0;
    top: 0;
  }

  .partners-text {
    color: rgb(0, 151, 151, 0.8);
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .deposit-image-col {
    display: none;
  }
}
</style>
