<template>
  <div class="section" id="mission-section">
    <v-container class="fill-height" id="mission_block" fluid>
      <v-container class="fill-height justify-center">
        <v-row class="deposit_row">
          <div class="col mission-description">
            <v-row class="mission-description__title">
              {{ $t("mission.title") }}
            </v-row>
            <v-row class="mission-description__text mb-7" v-html="$t('mission.description1')">
            </v-row>

            <v-row class="alert align-center">
              <div class="alert_back">
              </div>
              <v-col cols="auto" class="exclamation">
                !
              </v-col>
              <v-col class="alert__text justify-center align-center">
                  {{ $t("mission.description2") }}
              </v-col>
            </v-row>
          </div>
          <div class="col">
            <div class=" image-wrapper">
              <div class="square1"></div>
              <div class="square2"></div>
            </div>
          </div>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Mission"
}
</script>


<style scoped lang="scss">
.alert {
  background-color: rgb(0, 151, 151, 0.9);
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 3px;
  position: relative;
  margin-bottom: -10rem !important;
  z-index: 1000;
  width: min(50vw, 800px);


  .exclamation {
    font-size: min(200px, 10vw);
    line-height: min(170px, 10vw);
    color: white;
    font-weight: bold;
  }

  &_back {
    position: absolute;
    height: 100%;
    width: 50vw;
    top: 0;
    margin-left: -50vw;
    background-color: rgb(0, 151, 151, 0.9);
    box-shadow: rgba(0, 0, 0, 0.15) -3px 3px 3px;
  }

  &__text {
    font-size: min(30px, 1.5vw);
    color: white;
    position: relative;
  }
}

.image-wrapper {
  position: relative;
  padding: 0;

  height: 100%;
  background: url("../assets/img/deposits_image.jpg");
  background-size: cover;
  background-position: center left;
  min-height: min(60vh, 40vw);
  min-width: min(40vw, 300px);

  .square1, .square2 {
    width: 30%;
    height: 30%;
    position: absolute;
  }

  .square1 {
    right: 0;
    bottom: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    border-right: white 1rem solid;
    border-bottom: white 1rem solid;
  }

  .square2 {
    left: 0;
    top: 0;
    margin-top: 20px;
    margin-left: 20px;
    border-top: white 1rem solid;
    border-left: white 1rem solid;
  }

}

.deposit_row {
  padding-left: 7vw;
  padding-right: 7vw;
  margin-top: -5rem;
}

#mission_block {
  position: relative;
  min-height: 100vh;
}

.background_image {
  overflow: hidden;
  background-size: cover;
  background-image: url("../assets/img/deposits_back.jpg");
  background-position: center center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0.4;
}


.mission-description {

  width: 20vw !important;

  vertical-align: top;

  &__title {
    color: #009999;
    font-weight: 700;
    font-size: 5vw;
    line-height: 3.5vw;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
  }

  &__text {
    font-weight: 400;
    font-size: 2.3vw;
    line-height: 3vw;
  }

}

.deposit_image {
  width: 100%;
}

@media (max-width: 600px) {
  #mission_block {
    height: auto;
    min-height: 0;
    padding-top: 8rem;
    padding-bottom: 5rem;
  }

  .square1, .square2 {
    width: 10% !important;
    height: 10% !important;
    position: absolute;
  }

  .square1 {
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    border-right: white 5px solid !important;
    border-bottom: white 5px solid !important;
  }

  .square2 {
    margin-top: 10px !important;
    margin-left: 10px !important;
    border-top: white 5px solid !important;
    border-left: white 5px solid !important;
  }
}

</style>
