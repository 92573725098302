<template>
  <div class="section">
    <v-container id="why-to-choose-section" class="fill-height" fluid>
      <v-container>
        <v-row class="align-center justify-center">
          <div class="section-title" v-html="$t('whyToChoose.mainTitle')">
            Почему стоит выбрать нас?
          </div>
        </v-row>
        <v-row class="justify-center align-content-center pt-5">
          <v-row class="justify-center">
            <v-col sm="auto" cols="12">
              <v-row class="justify-center fill-height" no-gutters>
                <div class="card">
                  <v-row class="card__icon justify-center"><img src="../assets/choose3.svg" alt=""></v-row>
                  <v-row class="card__name justify-center" v-html="$t('whyToChoose.card1.title')">МАСШТАБИРУЕМОСТЬ</v-row>
                  <v-row class="card__list justify-center">
                    <ul>
                      <li v-html="$t('whyToChoose.card1.li1')">нужна только проба добываемой и закачиваемой жидкости</li>
                      <li v-html="$t('whyToChoose.card1.li2')">отсутствуют ограничения по глубине, давлению и максимальной темпетаруре скважин</li>
                    </ul>
                  </v-row>
                </div>
              </v-row>
            </v-col>
            <v-col sm="auto" cols="12">
              <v-row class="justify-center fill-height" no-gutters>
                <div class="card">
                  <v-row class="card__icon justify-center"><img src="../assets/choose1.svg" alt=""></v-row>
                  <v-row class="card__name justify-center" v-html="$t('whyToChoose.card2.title')">ЭКОНОМИЯ И БЫСТРОТА</v-row>
                  <v-row class="card__list justify-center">
                    <ul>
                      <li v-html="$t('whyToChoose.card2.li1')">без остановки скважин</li>
                      <li v-html="$t('whyToChoose.card2.li2')">без сложных исследований</li>
                    </ul>
                  </v-row>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="justify-center align-content-center pt-5">
          <v-row class="justify-center">
            <v-col sm="auto" cols="12">
              <v-row class="justify-center fill-height" no-gutters>
                <div class="card">
                  <v-row class="card__icon justify-center"><img src="../assets/choose2.svg" alt=""></v-row>
                  <v-row class="card__name justify-center" v-html="$t('whyToChoose.card3.title')">ДЕЯТЕЛЬНОСТЬ</v-row>
                  <v-row class="card__list justify-center">
                    <ul>
                      <li v-html="$t('whyToChoose.card3.li1')">оптимальная стоимость работ</li>
                      <li v-html="$t('whyToChoose.card3.li2')">качественные решения широкого спектра задач</li>
                    </ul>
                  </v-row>
                </div>
              </v-row>
            </v-col>
            <v-col sm="auto" cols="12">
              <v-row class="justify-center fill-height" no-gutters>
                <div class="card">
                  <v-row class="card__icon justify-center"><img src="../assets/choose4.svg" alt=""></v-row>
                  <v-row class="card__name justify-center" v-html="$t('whyToChoose.card4.title')">ЛЮДИ</v-row>
                  <v-row class="card__list justify-center">
                    <ul>
                      <li v-html="$t('whyToChoose.card4.li1')">высокая скорость исследований и выдачи заключений</li>
                      <li v-html="$t('whyToChoose.card4.li2')">профессиональная и отзывчивая команда</li>
                    </ul>
                  </v-row>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row></v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "WhyToChoose"
}
</script>

<style scoped lang="scss">

#why-to-choose-section {
  background-color: #009797;
  min-height: 100vh;
}

.section-title {
  font-weight: bold;
  color: white;
  font-size: 4vw;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
}

.card {
  background-color: #7FCBCB;
  padding: 2rem;
  width: min(20vw, 420px);
  min-width: 150px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__name {
    color: white;
    font-weight: bold;
    padding-top: 1rem;
    font-size: 1vw;
    text-align: center;
  }

  &__list {
    font-weight: 600;
    padding-top: 1rem;
    font-size: .9vw;
  }
}

@media (max-width: 600px) {
  #why-to-choose-section {
    background-color: #009797;
    min-height: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .card {

    width: 60%;
    &__icon > img {
      width: 30%;
    }

    &__name {
      font-size: 15px;
    }

    &__list {
      font-weight: 600;
      padding-top: 1rem;
      font-size: 10px;
    }
  }
}


</style>
