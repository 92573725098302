<template>
  <yandex-map
      @map-was-initialized="$emit('map-was-initialized', $event)"
      :settings="settings"
      :coords="coords"
      :zoom="13"
      :controls="['geolocationControl']"
  >
  </yandex-map>
</template>


<script>
export default {
  name: "YMap",
  props: ['settings', 'coords'],
}
</script>

<style scoped>

</style>