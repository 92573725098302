<template>
  <div class="section" id="statistic-section">
    <v-container class="align-center fill-height statistic-container">
      <v-row class="fill-height">
        <v-col cols="12" class="align-self-center">
          <v-row class="justify-center">
            <div class="statistic-title mt-5 mt-sm-0 mb-sm-16" v-html="$t('statistic.title')">
              с 2018 года было исследованно...
            </div>
          </v-row>

          <v-row class="justify-center first-row">
            <v-col cols="auto" sm="3"><span class="number">5000</span> <br><span v-html="$t('statistic.text1')">скважин</span></v-col>
            <v-col cols="auto" sm="3"><span class="number">15</span> <br> <span v-html="$t('statistic.text2')">месторождений</span></v-col>
          </v-row>

          <v-row class="justify-center second-row mb-0 mb-sm-0">
            <v-col cols="auto" sm="3"><span class="number">12</span> <br> <span v-html="$t('statistic.text3')">стратиграфических <br> единиц</span></v-col>
            <v-col cols="auto" sm="3"><span class="number">6</span> <br> <span v-html="$t('statistic.text4')">гидрогеологических <br> комплексов</span></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
export default {
  name: "Statistic"
}
</script>

<style scoped lang="scss">
#statistic-section {
  height: 100vh;
  background-color: #009797;
  background-image: url("../assets/img/statistic-background.png");
  background-size: 70%;
  background-position: center center;

  color: white;
  font-weight: bold;
  font-size: 1.5vw;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.statistic-title {
  font-size: 2vw;
}

.number {
  font-size: 4vw;
}

.statistic-container {
  //backdrop-filter: blur(4px);
}

@media (max-width: 600px) {

  #statistic-section {
    height: auto;
    font-size: 2.5vw;
  }

  .statistic-title {
    font-size: 4vw;
  }

  .number {
    font-size: 6vw;
  }

}
</style>
