<template>
  <div class="section" id="how-it-works-section">
    <v-container class="justify-center fill-height" fluid>
      <v-row>
        <v-row class="justify-center" no-gutters>
          <div class="slide--title" v-html="$t('howItWorks.title')">Как это работает</div>
        </v-row>

        <v-carousel
          class="fill-height"
          hide-delimiter-background
          id="carousel"
          height="60vh"
        >
          <v-carousel-item
            class="fill-height slide"
            eager
          >
            <v-container class="align-center fill-height" fluid>
              <v-row>
                <v-container fluid>
                  <v-row class="justify-center align-self-center">
                    <div class="slide--subtitle" v-html="$t('howItWorks.slide1.title')">ОТБОР ПРОБ И ЛАБОРАТОРНЫЕ АНАЛИЗЫ</div>
                  </v-row>
                  <v-row class="row-images justify-center align-self-center " no-gutters>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide1_1.png" alt="">
                      <div class="column-title">
                        <span class="column-title__number">1</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide1.subtitle1')">отбор проб</span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide1_2.png" alt="">
                      <div class="column-title">
                        <span class="column-title__number">2</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide1.subtitle2')">транспортировка</span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide1_3.jpg" alt="">
                      <div class="column-title">
                        <span class="column-title__number">3</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide1.subtitle3')">пробоподготовка</span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide1_4.png" alt="">
                      <div class="column-title">
                        <span class="column-title__number">4</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide1.subtitle4')">измерения (замеры)</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
          </v-carousel-item>
          <v-carousel-item
            class="fill-height slide"
            eager
          >
            <v-container class="align-center fill-height" fluid>
              <v-row>
                <v-container fluid>
                  <v-row class="justify-center align-self-center">
                    <div class="slide--subtitle" v-html="$t('howItWorks.slide2.title')">ПОДГОТОВКА ДАННЫХ И НЕЙРОСЕТЕВОЙ АНАЛИЗ</div>
                  </v-row>
                  <v-row class="row-images justify-center align-self-center " no-gutters>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide2_1.png" alt="">
                      <div class="column-title">
                        <span class="column-title__number">5</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide2.subtitle2')">база данных</span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide2_2.png" alt="" style="background-color: rgb(255, 255, 255); border-radius: 5%">
                      <div class="column-title">
                        <span class="column-title__number">6</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide2.subtitle3')">расчет</span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide2_3.png" alt="">
                      <div class="column-title">
                        <span class="column-title__number">7</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide2.subtitle4')">интерпретация</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
          </v-carousel-item>
          <v-carousel-item
            class="fill-height slide"
            eager
          >
            <v-container class="align-center fill-height" fluid>
              <v-row>
                <v-container fluid>
                  <v-row class="justify-center align-self-center">
                    <div class="slide--subtitle" v-html="$t('howItWorks.slide3.title')">ВЫДАЧА РЕЗУЛЬТАТОВ И РЕКОМЕНДАЦИЙ</div>
                  </v-row>
                  <v-row class="row-images justify-center align-self-center " no-gutters>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide3_1.png" alt="">
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide3_2.png" alt="">
                      <div class="column-title third-column-title1">
                        <span class="column-title__number">8</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide3.subtitle2')">результаты и рекомендации</span>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="auto" md="auto" sm="auto" class="row-column align-center">
                      <img src="../assets/slides/slide3_3.png" alt="">
                      <div class="column-title third-column-title2">
                        <span class="column-title__number">8</span>
                        <span class="column-title__text" v-html="$t('howItWorks.slide3.subtitle2')">результаты и рекомендации</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  data() {
    return {
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
    }
  }
}
</script>

<style lang="scss">
#how-it-works-section {
  height: 100vh;
  background-color: #009797;

  img {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.slide--title {
  font-size: 4vw;
  font-weight: bold;
  color: white;
  margin-bottom: 4rem;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
}

.slide--subtitle {
  font-size: 2.5vw;
  font-weight: 500;
  color: white;
  margin-bottom: 3rem;
}

.slide {
  background-color: #009797;
}

.row-images {
  border-bottom: white 10px solid;
  padding-bottom: 2rem;

  img {
    height: 100%;
    max-width: 12vw;
  }

  .row-column {
    height: 10vw;
    margin-right: 2rem;
    margin-left: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .column-text {
    margin-top: auto;
    color: white;
    font-weight: 500;
    font-size: min(1vw, 40px);
    text-align: center;
    text-transform: uppercase;
  }
}

.column-title {
  position: absolute;
  top: 100%;
  left: 50%;
  text-align: center;
  margin-top: 3rem;
  width: 1000px;
  transform: translate(-50%, 0);

  &__number {
    color: white;
    font-size: 1.5vw;
    margin-right: 1rem;
  }

  &__text {
    color: white;
    font-size: 1vw;
  }

}

.third-column-title2 {
  display: none;
}

@media (max-width: 600px) {

  #how-it-works-section {
    height: auto;
    background-color: #009797;

    img {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }


  .slide--title {
    font-size: 4vw;
    font-weight: bold;
    color: white;
    margin: 0;
    margin-top: 2rem;
    text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
  }

  #carousel {
    height: auto !important;
  }

  #carousel .v-window__container {
    height: auto !important;
  }

  #carousel .v-carousel__item {
    height: auto !important;
    padding-bottom: 4rem;
  }

  .row-column {
    height: auto !important;
    width: auto !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    margin-bottom: 1rem !important;
  }

  .row-images {
    border-bottom: none;
    padding-bottom: 1rem;

    img {
      height: 100%;
      width: 100% !important;
      max-width: 100px;
    }
  }

  .column-title {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    margin-top: 0;
    width: 100%;
    transform: none;
    margin-bottom: 20px;

    &__number {
      color: white;
      font-size: 20px;
      margin-right: 3px;
    }

    &__text {
      color: white;
      font-size: 15px;
      margin-right: 0;
    }

  }

  .third-column-title1 {
    display: none;
  }

  .third-column-title2 {
    margin-top: 1rem;
    display: block;
  }

}
</style>
