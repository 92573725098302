<template>
<div class="section">
  <v-container class="footer-section pt-5 pb-5" fluid>
    <v-row class="justify-center">
      <v-col md="auto" class="text-center">© Geoindicator</v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">

.footer-section {
  font-weight: 400;
  color: white;
  background-color: #009797;
  font-size: .8vw;
}

@media (max-width: 600px) {

  .footer-section {
    font-size: 15px;
  }

}

</style>
