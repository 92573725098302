<template>
  <div class="section">
    <v-container class="pa-0 header-container" fluid>
      <v-container>
        <v-row class="header ma-0 text-subtitle-2 fill-height justify-center align-center">
          <v-col md="auto" class="align-self-center header-logo">
            <v-row class="fill-height">
              <img src="../assets/header-logo.png" alt="">
            </v-row>
          </v-col>
          <v-col>
            <a @click="moveToAbout">{{ $t("mainSection.about") }}</a>
          </v-col>
          <v-col>
            <a @click="moveToTasks">{{ $t("mainSection.tasks") }}</a>
          </v-col>
          <v-col>
            <a @click="moveToContacts">{{ $t("mainSection.contacts") }}</a>
          </v-col>
          <v-col>
            <v-row class="justify-center align-center">
              <v-col cols="auto">
                <span class="language-title">RU</span>
              </v-col>
              <v-col cols="auto">
                <v-switch
                    v-model="switcherModel"
                    class="ma-0"
                    color="orange"
                    hide-details
                    @click="changeLocale"
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="pl-0">
                <span class="language-title">ENG</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container id="main-block" class="fill-height" fluid>
      <v-container class="mt-auto">
        <v-row class="ma-0 justify-center">
          <img style="width: 800px; height: 200px" src="../assets/svg.svg" alt="">
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MainSection",
  data() {
    return {
      switcherModel: true,
      currentLocale: null
    }
  },
  methods: {
    moveToAbout() {
      const block = document.querySelector("div#mission-section");
      block.scrollIntoView({block: "start", behavior: "smooth"})
    },
    moveToTasks() {
      const block = document.querySelector("div#tasks-section");
      block.scrollIntoView({block: "start", behavior: "smooth"})
    },
    moveToContacts() {
      const block = document.querySelector("div#how-to-find-section");
      block.scrollIntoView({block: "start", behavior: "smooth"})
    },
    changeLocale() {
      console.log('Local has been changed')
      if (this.currentLocale === "RU") {
        this.currentLocale = "ENG"
      } else {
        this.currentLocale = "RU"
      }
      this.$i18n.locale = this.currentLocale.toLowerCase()
      localStorage.setItem("locale", this.currentLocale)
      this.$emit('locale-change')
    }
  },
  beforeMount() {
    this.currentLocale = localStorage.getItem("locale") || "ENG"
    this.switcherModel = this.currentLocale !== "RU";
    this.$i18n.locale = this.currentLocale.toLowerCase()
  }
}
</script>

<style scoped lang="scss">
#main-block {
  background-image: url("../assets/img/back.jpg");
  background-size: cover;
  background-position: center;
  height: calc(100vh - 14vh);
  padding: 0;
  color: white;

  img {
    max-width: 70vw;
  }
}

.company-name-column {

  font-weight: 400 !important;

  .company-name-column__subtitle {
    letter-spacing: 1rem;
  }
}

.header {
  text-align: center;
  font-size: 20px;
  font-weight: 500;

  a {
    color: white;
    text-decoration: none;
    font-size: 20px;
  }

  &__mail {
    font-weight: bold;
  }

  &_phone {
  }
}


.logo {
  display: block;
  height: 150px;
  margin: auto;
  margin-right: 3rem;
}

@media (max-width: 960px) {
  .logo {
    margin: auto;
  }
}

.header-company {

  color: white;

  &__logo {
    height: 60px;
  }

  &__title {
    font-size: 40px;
    font-weight: 500;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
  }
}

.header {
  min-height: 12vh;

  img {
    width: 25vw;
    max-width: 350px;
  }
}

.header-container {
  background-color: #009797;
}

.language-title {
  color: white;
  font-size: 20px;
}

@media (max-width: 600px) {
  #main-block {
    height: auto;
    min-height: 400px;
  }

  .header-logo {
    display: none;
  }
}
</style>
