<template>
  <div class="section" id="technology-section">

    <div class="black-background"></div>

    <v-container class="fill-height justify-center pt-5 pb-5">
      <v-row class="tech-pc">
        <v-col>
          <v-row class="justify-center">
            <div class="technology__title" v-html="$t('technology.title')">
              Технология <br> геохимического <br> мониторинга
            </div>
          </v-row>
          <v-row no-gutters>
            <v-col class="left-img">
              <img class="left-img" @mouseover="leftImageOn" @mouseleave="leftImageOff" src="../assets/img/tech1.png"
                   alt="">
            </v-col>
            <v-col class="right-img">
              <img class="right-img" @mouseover="rightImageOn" @mouseleave="rightImageOff" src="../assets/img/tech2.png"
                   alt="">
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <div class="technology-list">
              <ul>
                <li v-html="$t('technology.list1')">Разделение продукции скважин;</li>
                <li v-html="$t('technology.list2')">Определение источника обводнения;</li>
                <li v-html="$t('technology.list3')">Установление не герметичности ствола скважин и оборудования;</li>
                <li v-html="$t('technology.list4')">Мониторинг участков закачки ПАВ;</li>
                <li v-html="$t('technology.list5')">Сопровождение закачки CO2;</li>
              </ul>
            </div>
          </v-row>
          <v-row class="alert-wrapper">
            <v-container class="cons">
              <v-row class="cons__year justify-center">2019 - {{ new Date().getFullYear() }}</v-row>
              <v-row class="cons__title justify-center" v-html="$t('technology.pros.title')">Геохимический мониторинг
              </v-row>
              <v-row class="justify-center">
                <v-col class="cons__alert" md="auto">
                  <v-row class="justify-center">
                    <div class="cons__list-title" v-html="$t('technology.pros.listTitle')">
                      ДОСТОИНСТВА:
                    </div>
                  </v-row>
                  <v-row class="justify-center">
                    <ul class="cons__list">
                      <li v-html="$t('technology.pros.list1')">скорость получения результатов</li>
                      <li v-html="$t('technology.pros.list2')">точность результатов</li>
                      <li v-html="$t('technology.pros.list3')">отсутсиве рисков и потерь продукции</li>
                    </ul>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="pros">
              <v-row class="pros__year justify-center">1950 - 2018</v-row>
              <v-row class="pros__title justify-center" v-html="$t('technology.cons.title')">Традиционные методы <br>
                исследования
              </v-row>
              <v-row class="justify-center">
                <v-col class="pros__alert" md="auto">
                  <v-row class="justify-center">
                    <div class="pros__list-title" v-html="$t('technology.cons.listTitle')">
                      НЕДОСТАТКИ:
                    </div>
                  </v-row>
                  <v-row class="justify-center">
                    <ul class="pros__list">
                      <li v-html="$t('technology.cons.list1')">высокие затраты</li>
                      <li v-html="$t('technology.cons.list2')">риски и осложнения при замера</li>
                      <li v-html="$t('technology.cons.list3')">потеря продукции</li>
                    </ul>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>

      <!-- Mobile &ndash -->

      <v-row class="tech-phone">
        <v-col>
          <v-row>
            <v-row class="justify-start ml-5">
              <div class="technology__title" v-html="$t('technology.title')">
                Технология <br> геохимического <br> мониторинга
              </div>
            </v-row>
          </v-row>

          <v-row class="justify-center">
            <v-col cols="10">
              <div class="technology-list">
                <ul>
                  <li v-html="$t('technology.list1')">Разделение продукции скважин;</li>
                  <li v-html="$t('technology.list2')">Определение источника обводнения;</li>
                  <li v-html="$t('technology.list3')">Установление не герметичности ствола скважин и оборудования;</li>
                  <li v-html="$t('technology.list4')">Мониторинг участков закачки ПАВ;</li>
                  <li v-html="$t('technology.list5')">Сопровождение закачки CO2;</li>
                </ul>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-2" no-gutters>
            <v-col class="left-img">
              <img class="left-img" @mouseover="leftImageOn" @mouseleave="leftImageOff" src="../assets/img/tech1.png"
                   alt="">
            </v-col>
            <v-col class="right-img">
              <img class="right-img" @mouseover="rightImageOn" @mouseleave="rightImageOff" src="../assets/img/tech2.png"
                   alt="">
            </v-col>
          </v-row>

          <v-row class="alert-wrapper">
            <v-container class="cons">
              <v-row class="cons__year justify-center">2019 - {{ new Date().getFullYear() }}</v-row>
              <v-row class="cons__title justify-center" v-html="$t('technology.pros.title')">Геохимический мониторинг
              </v-row>
              <v-row class="justify-center">
                <v-col class="cons__alert" md="auto">
                  <v-row class="justify-center">
                    <div class="cons__list-title" v-html="$t('technology.pros.listTitle')">
                      ДОСТОИНСТВА:
                    </div>
                  </v-row>
                  <v-row class="justify-center">
                    <ul class="cons__list">
                      <li v-html="$t('technology.pros.list1')">скорость получения результатов</li>
                      <li v-html="$t('technology.pros.list2')">точность результатов</li>
                      <li v-html="$t('technology.pros.list3')">отсутсиве рисков и потерь продукции</li>
                    </ul>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="pros">
              <v-row class="pros__year justify-center">1950 - 2018</v-row>
              <v-row class="pros__title justify-center" v-html="$t('technology.cons.title')">Традиционные методы <br>
                исследования
              </v-row>
              <v-row class="justify-center">
                <v-col class="pros__alert" md="auto">
                  <v-row class="justify-center">
                    <div class="pros__list-title" v-html="$t('technology.cons.listTitle')">
                      НЕДОСТАТКИ:
                    </div>
                  </v-row>
                  <v-row class="justify-center">
                    <ul class="pros__list">
                      <li v-html="$t('technology.cons.list1')">высокие затраты</li>
                      <li v-html="$t('technology.cons.list2')">риски и осложнения при замера</li>
                      <li v-html="$t('technology.cons.list3')">потеря продукции</li>
                    </ul>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Technology",
  methods: {
    leftImageOn() {
      const back = document.getElementsByClassName('black-background')[1]
      const img = document.querySelectorAll('div.left-img')
      const pros = document.querySelectorAll('div.pros')
      pros.forEach(pros => {pros.classList.add('visible')})
      img.forEach(img => {img.classList.add('top')})
      back.classList.add('visible')
    },
    rightImageOn() {
      const back = document.getElementsByClassName('black-background')[1]
      const img = document.querySelectorAll('div.right-img')
      const cons = document.querySelectorAll('div.cons')
      cons.forEach(cons => {cons.classList.add('visible')})
      img.forEach(img => {img.classList.add('top')})
      back.classList.add('visible')
    },
    leftImageOff() {
      const back = document.getElementsByClassName('black-background')[1]
      const img = document.querySelectorAll('div.left-img')
      const pros = document.querySelectorAll('div.pros')
      pros.forEach(pros => {pros.classList.remove('visible')})
      img.forEach(img => {img.classList.remove('top')})
      back.classList.remove('visible')
    },
    rightImageOff() {
      const back = document.getElementsByClassName('black-background')[1]
      const img = document.querySelectorAll('div.right-img')
      const cons = document.querySelectorAll('div.cons')
      cons.forEach(cons => {cons.classList.remove('visible')})
      img.forEach(img => {img.classList.remove('top')})
      back.classList.remove('visible')
    }
  }
}
</script>

<style lang="scss">

#technology-section {
  position: relative;
  min-height: 100vh;
}

.black-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .8);
  opacity: .8;
  z-index: 10;
  visibility: hidden;
  transition: .05s;
}

.visible {
  visibility: visible !important;
}

.top {
  z-index: 1000 !important;
}

.left-img {
  animation-delay: 2s;
  img {
    display: block;
    margin-left: auto;
  }
}

.left-img, .right-img {
  width: 15vw;
  animation-name: mouse-hover;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes mouse-hover {
  0%   {  }
  10%  {
    transform: translateY(-4px);
  }
  20%  {
    transform: translateY(0);
  }
  100% {  }
}

.technology__title {
  font-size: 3vw;
  font-weight: bold;
  color: #009999;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
}

.technology-list {
  font-size: 1.5vw;
  margin-top: 1rem;
}

.cons {
  z-index: 10000;
  visibility: hidden;
  margin-top: 5rem;
  position: absolute;

  &__year {
    font-size: 2vw;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  &__title {
    color: white;
    font-weight: bold;
    font-size: 1vw;
  }

  &__list-title {
    color: rgb(0, 151, 151);
    font-size: 1.7vw;
    font-weight: 900;
  }

  &__list {
    color: rgb(0, 151, 151);
    font-size: 1vw;
    font-weight: 700;
  }

  &__alert {
    background-color: white;
    border-radius: 1rem;
    padding: 3rem !important;
    margin-top: 2rem;
  }
}

.pros {
  position: absolute;
  left: 0;
  z-index: 10000;
  visibility: hidden;
  margin-top: 5rem;

  &__year {
    font-size: 2vw;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  &__title {
    color: white;
    font-weight: bold;
    font-size: 1vw;
    text-align: center;
  }

  &__list-title {
    color: rgb(100, 100, 100);
    font-size: 1.7vw;
    font-weight: 900;
  }

  &__list {
    color: rgb(100, 100, 100);
    font-size: 1vw;
    font-weight: 700;
  }

  &__alert {
    background-color: white;
    border-radius: 1rem;
    padding: 3rem !important;
    margin-top: 2rem;
  }
}

.alert-wrapper {
  position: relative;
}

.tech-phone {
  display: none !important;
}

@media (max-width: 600px) {
  #technology-section {
    min-height: 0 !important;
    overflow: hidden;
  }

  .black-background {
    width: 100%;
    height: 100%;
  }

  .tech-pc {
    display: none !important;
  }

  .tech-phone {
    display: flex !important;
  }

  .technology__title {
    font-size: 25px;
    line-height: 35px;
  }

  .technology-list {
    font-size: 15px;
    margin-top: 2rem;
  }

  .left-img {
    img {
      display: block;
      margin-left: auto;
    }
  }

  .left-img, .right-img {
    width: 30vw;
  }

  .alert-wrapper {
    height: 300px;
  }

  .cons {
    margin-top: 0;
    position: absolute;
    visibility: hidden;

    &__year {
      font-size: 25px;
      font-weight: bold;
      color: white;
      text-align: center;
    }

    &__title {
      color: white;
      font-weight: bold;
      font-size: 20px;
    }

    &__list-title {
      color: rgb(0, 151, 151);
      font-size: 20px;
      font-weight: 900;
    }

    &__list {
      color: rgb(0, 151, 151);
      font-size: 18px;
      font-weight: 700;
    }

    &__alert {
      background-color: white;
      border-radius: 1rem;
      padding: 3rem !important;
      margin-top: 2rem;
    }
  }

  .pros {
    margin-top: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;

    &__year {
      font-size: 25px;
      font-weight: bold;
      color: white;
      text-align: center;
    }

    &__title {
      color: white;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }

    &__list-title {
      color: rgb(100, 100, 100);
      font-size: 20px;
      font-weight: 900;
    }

    &__list {
      color: rgb(100, 100, 100);
      font-size: 18px;
      font-weight: 700;
    }

    &__alert {
      background-color: white;
      border-radius: 1rem;
      padding: 3rem !important;
      margin-top: 2rem;
    }
  }
}
</style>
