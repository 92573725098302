<template>
  <div class="section" id="tasks-section">

    <div class="black-background"></div>

    <v-container fluid class="fill-height">
      <v-container class="justify-center align-center">
        <v-row class="justify-center">
          <div class="tasks__title" v-html="$t('tasks.title')">Решаемые задачи</div>
        </v-row>
        <v-row class="justify-center tasks-row" no-gutters>
          <div @mouseover="over(1)" @mouseleave="leave(1)" class="card" id="card1">

          </div>
          <div @mouseover="over(2)" @mouseleave="leave(2)" class="card" id="card2">

          </div>
          <div @mouseover="over(3)" @mouseleave="leave(3)" class="card" id="card3">

          </div>

          <div class="description description4">
            <span class="description__number">4</span>
            <span class="description__text"
                  v-html="$t('tasks.task4.title')">Мониторинг эффективности скважин после ГТМ</span>
            <div class="description__subtext" v-html="$t('tasks.task4.description')">Проведение работ по скважинам после
              применения методов воздействия на пласт, изменения конструкции скважин и пр.
            </div>
          </div>

          <div class="description description5">
            <span class="description__number">5</span>
            <span class="description__text"
                  v-html="$t('tasks.task5.title')">Площадной анализ фильтрационных потоков</span>
            <div class="description__subtext" v-html="$t('tasks.task5.description')">Анализ продвижения фронта
              заводнения по площади
            </div>
          </div>

        </v-row>
        <v-row class="justify-center tasks-row" no-gutters>
          <div @mouseover="over(4)" @mouseleave="leave(4)" class="card" id="card4">

          </div>
          <div @mouseover="over(5)" @mouseleave="leave(5)" class="card" id="card5">

          </div>

          <div class="description description1">
            <span class="description__number">1</span>
            <span class="description__text" v-html="$t('tasks.task1.title')">Установление объемные долей продукции (воды)</span>
            <div class="description__subtext" v-html="$t('tasks.task1.description')">При вовлечении в работу совместно
              нескольких пластов, выявление основного обводняющегося объекта по скважин
            </div>
          </div>

          <div class="description description2">
            <span class="description__number">2</span>
            <span class="description__text" v-html="$t('tasks.task2.title')">Анализ скважин сложной конструкции</span>
            <div class="description__subtext" v-html="$t('tasks.task2.description')">Проведение исследований в
              скважинах: с бурением боковых стволов, боковых горизонтальных стволов, проведенных работ по гидроразрыву и
              пр.
            </div>
          </div>

          <div class="description description3">
            <span class="description__number">3</span>
            <span class="description__text" v-html="$t('tasks.task3.title')">Определение нарушений и заколонных перетоков</span>
            <div class="description__subtext" v-html="$t('tasks.task3.description')">Выявление источника воды, не
              свойственного перфорированому в скважине пласту
            </div>
          </div>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Tasks",
  data() {
    return {
      enteredElements: []
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async over(elem) {
      this.enteredElements.push(elem)
      const card = document.getElementById(`card${elem}`)
      const back = document.getElementsByClassName('black-background')[0]
      const description = document.getElementsByClassName(`description${elem}`)[0]
      await this.sleep(30);
      if (!this.enteredElements.includes(elem)) {
        return
      }
      description.classList.add('visible')
      back.classList.add('visible')
      card.classList.add('top')
    }
    ,
    async leave(elem) {
      const index = this.enteredElements.indexOf(elem)
      if (index > -1) {
        this.enteredElements.splice(index, 1);
      }
      const card = document.getElementById(`card${elem}`)
      const back = document.getElementsByClassName('black-background')[0]
      const description = document.getElementsByClassName(`description${elem}`)[0]
      description.classList.remove('visible')
      back.classList.remove('visible')
      card.classList.remove('top')
    }
  }
}
</script>

<style scoped lang="scss">

#tasks-section {
  position: relative;
  min-height: 100vh;
}

.black-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .8);
  opacity: .8;
  z-index: 10;
  visibility: hidden;
  transition: .15s;
}

.visible {
  visibility: visible !important;
}

.tasks__title {
  color: #009999;
  font-weight: bold;
  font-size: 4vw;
  text-align: center;
  margin-bottom: 5rem;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
}

.card {
  width: 17vw;
  height: 17vw;
  border-radius: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  border: 7px #009999 solid;
  animation-name: mouse-hover;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.top {
  z-index: 100;
}

.light {
  opacity: 1;
}

.tasks-row {
  position: relative;
}

.description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  text-align: center;
  visibility: hidden;


  &__number {
    color: #009797;
    font-size: 2.5vw;
    font-weight: bold;
    margin-right: 1rem;
  }

  &__text {
    color: white;
    font-weight: bold;
    font-size: 2vw;
  }

  &__subtext {
    color: white;
    font-size: 1.5vw;
  }

}

#card1 {
  background: url("../assets/tasks/task1.png") center center;
  background-size: cover;
  animation-delay: 0s;
}

#card2 {
  background: url("../assets/tasks/task2.png") center center;
  background-size: cover;
  animation-delay: 2s;
}

#card3 {
  background: url("../assets/tasks/task3.png") center center;
  background-size: cover;
  animation-delay: 4s;
}

#card4 {
  background: url("../assets/tasks/task4.png") center center;
  background-size: cover;
  animation-delay: 6s;
}

#card5 {
  background: url("../assets/tasks/task5.png") center center;
  background-size: cover;
  animation-delay: 8s;
}

@keyframes mouse-hover {
  0%   { transform: scale(1); }
  10%  {
    transform: scale(1.05);
    border-color: #ffffff;
  }
  20%  {
    transform: scale(1);
    border-color: #009999;
  }
  100% { transform: scale(1); }
}

@media (max-width: 600px) {

  #tasks-section {
    position: relative;
    min-height: 0;
    height: auto;
    overflow: hidden;
    width: 100%;
  }

  .card {
    width: 32vw;
    height: 32vw;
    border-radius: 50%;
    margin-left: 1rem;
    margin-right: 1rem;
    border: 4px #009999 solid;
  }

  .black-background {
    width: 100%;
  }

  .description {
    line-height: 60%;

    &__subtext {
      margin-top: .5rem;
    }
  }

  .tasks__title {
    margin-bottom: 2rem;
  }

}

</style>
